import CircleIcon from '@mui/icons-material/Circle';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { useForm } from "react-hook-form";
import { Button, Table } from "@mui/joy";
import { CategoriaServices, ChecklistServices } from "../../services";
import { useState } from "react";
import { LoadingScreen } from "../UtilsComponents/LoadingScreen";
import { UpdateTarea } from "./UpdateTarea";
import { CategoryModel, ChecklistCreateModel, TareaCreateModel, TareaModel } from "../../types";
import { errorParser } from "../../utilities";
import { ErrorMessage, LoadingRequest } from "../UtilsComponents";
import { AgregarTareas } from "./AgregarTareas";
import { DeleteTarea } from './DeleteTarea';
import { EmailInput } from '../UtilsComponents/EmailInput';
import { CorreosLista } from '../../types/checklist';
import ClearIcon from '@mui/icons-material/Clear';

export function ListDetail() {
    const navigate = useNavigate();
    const { id } = useParams(); 
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const checklistService = new ChecklistServices(token);
    const categoryService = new CategoriaServices(token);

    const [tareas, setTareas] = useState<Array<TareaCreateModel>>([]);
    const [categories, setCategories] = useState<Array<CategoryModel>>([]);
    const [listTareas, setListTareas] = useState<TareaModel[]>([]);

    const [showAddRevision, setShowAddRevision] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);
    const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
    const [isLoadingCorreos, setIsLoadingCorreos] = useState<boolean>(false);

    const [showEmailInput, setShowEmailInput] = useState<boolean>(false);
    const [correosAdjuntos, setCorreosAdjuntos] = useState<CorreosLista>({ correos: [] });

    const { 
        register,
        watch,
        handleSubmit,
        setValue,
        formState: {
            isDirty,
            errors,
        }
     } = useForm({
        defaultValues: async () => {
            const lista = await checklistService.getListById(id)

            const categorias = await categoryService.getCategories()

            const correos = await checklistService.getCorreosAdjuntos(parseInt(id ?? "0"));

            Promise.all([lista, categories, correos])
                .then(values => {
                    setIsLoaded(true)
                    setCategories(categorias);
                });

            setListTareas(lista.tareas);
            setCorreosAdjuntos(correos);

            return lista; 
        }
    });

    const nombreLista = watch("nombre");

    const saveUpdate = handleSubmit(async (data) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const lista: ChecklistCreateModel = {
                nombre: data.nombre,
                descripcion: data.descripcion,
                tareasRequest: tareas,
                horaLimite: data.horaLimite,
                correosAdjuntos: data.correosAdjuntos
            }

            const response = await checklistService.updateListaById(data.id, lista);

            if(response.status == 200)
                navigate("/revision")

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    const deleteChecklist = async () => {
        try {
            const checklistId = parseInt(id ?? "0");
            const response = await checklistService.deleteChecklist(checklistId)

            if(response.status == 204)
                navigate("/revision");

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    }

    const deleteTarea = async (tareaId: number, index: number) => {
        try {
            const response = await checklistService.deleteTarea(tareaId);

            if(response.status == 204) {
                listTareas.splice(index, 1)
                setListTareas([...listTareas]);
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    }

    const deleteCorreoAdjunto = async (correoId: number) => {
        try {
            setIsLoadingCorreos(true);

            const response = await checklistService.deleteCorreoAdjunto(correoId);

            if(response.status == 204) {
                const correos = await checklistService.getCorreosAdjuntos(parseInt(id ?? "0"));
                setCorreosAdjuntos(correos);
                setIsLoadingCorreos(false);
            }

        } catch(error) {
            // TODO: Agregar un mensaje de error visible para el usuario.
            setIsLoadingCorreos(false);
            console.error(error);
        }
    }

    return (
        <>{!isLoaded
        ? <LoadingScreen />
        :
        <div>
            <h2 className="text-3xl my-4 font-semibold">Detalles: {nombreLista}</h2>

            <form className="grid grid-cols-2 gap-y-4" onSubmit={saveUpdate}>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Folio</label>
                    <input 
                        { ...register("id") }
                        disabled
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Nombre</label>
                    <input 
                        { ...register("nombre", {
                            required: "La lista debe tener un nombre.",
                            maxLength: 50
                        }) }
                        aria-invalid={errors.nombre ? "true" : "false"}
                        className="inputform"/>
                    {errors.nombre?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.nombre.message}</p>
                    )}
                    {errors.nombre?.type === "maxLength" && (
                        <p className="valErrorText col-start-2" role="alert">Tamaño maxino del nombre es 50 caracteres.</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Hora limite para hacer la revision</label>
                    <input 
                        {...register("horaLimite")}
                        className="inputform"
                        type="time" />
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="descripcion">Adjuntas Correos</label>
                    <div className="w-full">
                        <div>
                            <p className="font-semibold">Correos Agregados</p>
                            {isLoadingCorreos
                            ? <div className="flex justify-center items-center h-[100px]">
                                <LoadingRequest />
                            </div>
                            : <div className="max-h-[100px] overflow-auto sm:grid sm:grid-cols-2 sm:my-2 sm:gap-x-3">
                                {correosAdjuntos.correos.length === 0
                                ? <p className="">No Hay correos adjuntos para esta lista.</p>
                                : correosAdjuntos.correos.map((email, index) => (
                                    <div key={index} className="flex justify-between group">
                                        <p>{email.correo}</p>
                                        <div
                                            onClick={() => deleteCorreoAdjunto(email.id)}
                                            className="hover:bg-gray-100 hover:rounded opacity-0 group-hover:opacity-100 transition duration-150 cursor-pointer h-[24px] flex justify-center items-center">
                                            <ClearIcon />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            }
                        </div>
                        {showEmailInput
                        ? <>
                            <EmailInput 
                                setValue={setValue}/>
                        </>
                        : <>
                            <p
                                className="italic cursor-pointer no-underline hover:underline hover:text-blue-900"
                                onClick={() => setShowEmailInput(!showEmailInput)}>
                                Agregar más correos
                            </p>
                        </>
                        }
                    </div>
                </div>

                <div className="grid grid-cols-1 justify-between col-span-2">

                    <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200">
                        <Table
                            sx={(theme) => ({
                                "th": {bgcolor: "#111827", color: "#ffffff"},
                            })}>
                            <thead>
                                <tr>
                                    <th>Tarea</th>
                                    <th className="hidden sm:block"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {listTareas.length == 0
                            ? <tr>
                                <td colSpan={2}>
                                    <p className="text-center italic font-semibold text-[15px]">
                                        No hay tareas creadas en este checklist
                                    </p>
                                </td>
                            </tr>
                            : listTareas.map((tarea, index) => (
                                <>
                                <tr key={index}>
                                    <td className="p-0">
                                        <UpdateTarea 
                                            categories={categories}
                                            tarea={tarea}/>
                                    </td>
                                    <td className="h-[41px] flex">
                                        <DeleteTarea 
                                            deleteTarea={deleteTarea}
                                            tareaId={tarea.id}
                                            index={index}/>
                                    </td>
                                </tr>
                                {tarea.version2 && (
                                <tr>
                                    <td colSpan={2}>
                                    <p>Equipos por revisar:</p>
                                    {tarea.equipos.map((equipo, index) => (
                                        <div key={index} className="flex gap-3 my-1">
                                            <div className="flex items-center"><CircleIcon sx={{ fontSize: 10}}/></div>
                                            <div className="grid grid-cols-2 gap-4">
                                                <div className="flex gap-2">
                                                    <div className="font-medium">Equipo: </div>
                                                    <div>{equipo.nombre}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    </td>
                                </tr>
                                )}
                                </>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                   <div className="w-fit">
                        <p className="italic cursor-pointer no-underline hover:underline hover:text-blue-900"
                            onClick={() => setShowAddRevision(!showAddRevision)}>
                            Agregar más revisiones
                        </p>
                   </div>
                </div>

                {showAddRevision && (
                <div className="grid grid-cols-1 justify-between col-span-2">
                    <AgregarTareas 
                        tareas={tareas}
                        setTareas={setTareas}
                        setValue={setValue}/>
                </div>
                )}

                {hasError && (
                    <ErrorMessage
                        errorMessage={errorMessage} />
                )}

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {requestLoading
                    ? <LoadingRequest />
                    : <>
                    <Button 
                        size="sm" 
                        color="danger" 
                        className="w-20 mx-2" 
                        onClick={() => setConfirmationDelete(true)}>
                        Eliminar
                    </Button>
                    <Button size="sm" 
                            color="success" 
                            className="w-20" 
                            disabled={!isDirty}
                            type="submit">
                        Guardar
                    </Button>
                    </>}
                    <Link to="/revision">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Regresar</Button>
                    </Link>

                </div>

                <div className="relative">
                {confirmationDelete && (
                <div className="fixed inset-0 flex items-center justify-center z-50">

                    <div className="fixed inset-0 bg-gray-800 opacity-50"></div>

                    <div className="flex col-span-2 w-full justify-end z-50 max-w-lg content-between">
                        <div className="p-4 w-full h-[190px] border rounded-md shadow-md bg-white">

                            <div className='py-5'>
                                <p className="mb-2 font-medium col-span-2 text-2xl text-center">¿Seguro que quiere eliminar el checklist?</p>
                                <p className="mb-2 font-medium text-sm col-span-2 text-center">(El historial de revisiones se conservará)</p>
                            </div>

                            <div className="flex justify-between col-span-2 gap-2 mt-4">
                                <button 
                                    className="py-1 text-sm font-medium bg-red-700 text-gray-50 w-20 rounded-md disabled:bg-red-300"
                                    onClick={deleteChecklist}>
                                    Eliminar
                                </button>
                                <button 
                                    className="py-1 text-sm font-medium bg-gray-700 text-gray-50 w-20 rounded-md"
                                    onClick={() => setConfirmationDelete(false)}>
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                )}
                </div>

            </form>

        </div>
        }</>
    );
}