import axios from "axios";
import { 
    FiltrosModel,
    TicketResponse,
    SolicitudResponse,
    BitacoraModel,
    MantenimientoResponse,
    EquipoModel,
    HistorialRevision,
    CodePrefixResponse
} from "../types";
import { 
    DoctorFilterResponse,
    DoctorForFilter,
    SucursalFromDbCitas
} from "../types/filter";


export class FiltroServices {

    /**
     * Filter for list of TicketResponse using FiltrosModel
     * 
     * @param { Array<TicketResponse> } list - List of TicketsResponse
     * @param { string } search - Search from searchbar filter
     * @param { FiltrosModel } filtros - FiltrosModel-baseds filters 
     * @returns { Array<TicketModel> } - Filtered list of TicketesResponse
     */
    public static filtrosTickets(list: Array<TicketResponse>, search: string, filtros: FiltrosModel): Array<TicketResponse> {
        return list.filter(ticket => {
            // filtro por categoria
            if(filtros.categoriaId != 0){
                if(ticket.categoriaId != filtros.categoriaId){
                    return false;
                }
            }

            // filtro por estado
            if(filtros.estado.length != 0) {
                if(ticket.estado != filtros.estado) {
                    return false;
                }
            }

            // filtro por sucursal
            if(filtros.sucursalId != 0) {
                if(ticket.sucursalId != filtros.sucursalId) {
                    return false;
                }
            }

            // filtro por urgencia
            if(filtros.urgencia.length != 0) {
                if(ticket.urgencia != filtros.urgencia) {
                    return false;
                }
            }

            // filtro por responsable
            if(filtros.responsableId != 0) {
                if(ticket.responsableId != filtros.responsableId) {
                    return false;
                }
            }

            // barra de busqueda por folio, titulo y equipo
            const folio = ticket.id.toString();
            const titulo = ticket.titulo.toUpperCase(); 
            const equipo = ticket.equipoNombre.toUpperCase();
            const searchFilter = search.toUpperCase();
            if(searchFilter.length !== 0){
                if(!folio.includes(searchFilter) && !titulo.includes(searchFilter) && !equipo.includes(searchFilter)){
                    return false;
                }
            }

            return true
        });
    }

    /**
     * Filter for list of SolicitudResponse using FiltrosModel
     * 
     * @param { Array<SolicitudResponse> } list - List of SolicitudResponse
     * @param { string } search - Search from searchbar filter
     * @param { FiltrosModel } filtros - FiltrosModel-based filters
     * @returns { Array<SolicitudResponse> } - Filtered list of SolicitudResponse
     */
    public static filtrosSolicitud(list: Array<SolicitudResponse>, search: string, filtros: FiltrosModel): Array<SolicitudResponse> {
        return list.filter(ticket => {
            // filtro por categoria
            if(filtros.categoriaId != 0){
                if(ticket.categoriaId != filtros.categoriaId){
                    return false;
                }
            }

            // filtro por estado
            if(filtros.estado.length != 0) {
                if(ticket.estado != filtros.estado) {
                    return false;
                }
            }

            // filtro por urgencia
            if(filtros.urgencia.length != 0) {
                if(ticket.urgencia != filtros.urgencia) {
                    return false;
                }
            }

            // filtro por sucursal
            if(filtros.sucursalId != 0) {
                if(ticket.sucursalId != filtros.sucursalId) {
                    return false;
                }
            }

            // filtro por responsable
            if(filtros.responsableId != 0) {
                if(ticket.responsableId != filtros.responsableId) {
                    return false;
                }
            }

            // barra de busqueda por folio, titulo
            const folio = ticket.id.toString();
            const titulo = ticket.titulo.toUpperCase(); 
            const searchFilter = search.toUpperCase();
            if(searchFilter.length !== 0){
                if(!folio.includes(searchFilter) 
                    && !titulo.includes(searchFilter) 
                    //&& !equipo.includes(searchFilter)
                ){
                    return false;
                }
            }

            return true
        });
    }

    /**
     * Filter for list of BitacoraModel using FiltrosModel
     * 
     * @param { Array<BitacoraModel> } list - List of BitacoraModel
     * @param { string } search - Search from searchbar filter
     * @param { FiltrosModel } filtros - FiltrosModel-based filters
     * @returns { Array<BitacoraModel> } - Filtered list of BitacoraModel
     */
    public static filtrosBitacora(list: Array<BitacoraModel>, search: string, filtros: FiltrosModel): Array<BitacoraModel> {
        return list.filter(row => {

            // barra de busqueda por folio, evento
            const folio = row.folio.toString();
            const evento = row.evento.toUpperCase(); 
            const description = row.descripcion.toUpperCase();
            const searchFilter = search.toUpperCase();

            if(searchFilter.length !== 0){
                if(!folio.includes(searchFilter) 
                    && !evento.includes(searchFilter) 
                    && !description.includes(searchFilter) 
                ){
                    return false;
                }
            }

            return true
        });
    }

    /**
     * Filter for list of MantenimientoResponse using FiltrosModel
     * 
     * @param { Array<MantenimientoResponse> } list - List of MantenimientoResponse 
     * @param { string } search - Search from searchbar filter
     * @param { FiltrosModel } filtros - FiltrosModel-based filter
     * @returns { Array<MantenimientoResponse> } - Filtered list of MantenimientoResponse
     */
    public static filtrosMantenimientos(list: Array<MantenimientoResponse>, search: string, filtros: FiltrosModel): Array<MantenimientoResponse> {
        return list.filter(ticket => {
            // filtro por categoria
            if(filtros.categoriaId != 0){
                if(ticket.categoriaId != filtros.categoriaId){
                    return false;
                }
            }

            // filtro por estado
            if(filtros.estado.length != 0) {
                if(ticket.status != filtros.estado) {
                    return false;
                }
            }

            // filtro por sucursal
            if(filtros.sucursalId != 0) {
                if(ticket.sucursalId != filtros.sucursalId) {
                    return false;
                }
            }

            // filtro por responsable
            if(filtros.responsableId != 0) {
                if(ticket.responsableId != filtros.responsableId) {
                    return false;
                }
            }

            // barra de busqueda por folio, titulo y equipo
            const folio = ticket.id.toString();
            const codigo = ticket.codigo?.toUpperCase(); 
            const equipo = ticket.nombreEquipo.toUpperCase();
            const searchFilter = search.toUpperCase();
            if(searchFilter.length !== 0){
                if(!folio.includes(searchFilter) 
                && !codigo?.includes(searchFilter) 
                && !equipo.includes(searchFilter)){
                    return false;
                }
            }

            return true
        });
    }

    /**
     * 
     * @param list 
     * @param filtros 
     * @returns 
     */
    public static filtrosEquipos(list: Array<EquipoModel>, filtros: FiltrosModel): Array<EquipoModel> {
        return list.filter(equipo => {

            if(filtros.subcategoriaId != 0) {
                if(equipo.subcategoriaId != filtros.subcategoriaId) {
                    return false;
                }
            }

            if(filtros.sucursalId != 0) {
                if(equipo.sucursal.id != filtros.sucursalId) {
                    return false;
                }
            }

            return true;
        });
    }

    public static filtrosChecklist(list: Array<HistorialRevision>, filtros: FiltrosModel): Array<HistorialRevision> {
        return list.filter(lista => {

            if(filtros.sucursalId != 0) {
                if(filtros.sucursalId != lista.sucursalId) {
                    return false
                }
            }

            if(filtros.responsableId != 0) {
                if(filtros.responsableId != lista.responsableId) {}
                    return false;
            }

            if(filtros.checklistId != 0) {
                if(filtros.checklistId != lista.listaId) {
                    return false;
                }
            }

            return true;
        });
    }

    /**
     * Filter for list of TicketResponse using FiltrosModel
     * 
     * @param { Array<DoctorForFilter> } list - List of DoctorForFilters 
     * @param { string } search - Search from searchbar filter
     * @returns { Array<DoctorForFilter> } - Filtered list of DoctorForFilters
     */
    public static filtrosSearchDoctores(list: Array<DoctorForFilter>, search: string): Array<DoctorForFilter> {
        return list.filter(doctor => {
            // barra de busqueda por folio, titulo y equipo
            const nombre = doctor.doctorName.toUpperCase();
            const searchFilter = search.toUpperCase();

            if(searchFilter.length !== 0){
                if(!nombre.includes(searchFilter)){
                    return false;
                }
            }

            return true
        });
    }

    public static filtrosSearchSucursales(list: Array<SucursalFromDbCitas>, search: string): Array<SucursalFromDbCitas> {
        return list.filter(sucursal => {

            const nombre = sucursal.nombre.toUpperCase();
            const searchFilter = search.toUpperCase();

            if(searchFilter.length !== 0){
                if(!nombre.includes(searchFilter)){
                    return false;
                }
            }

            return true
        });
    }

    public static filtrosCodeEquipos(list: Array<CodePrefixResponse>, search: string): Array<CodePrefixResponse> {
        return list.filter(codigo => {

            const significado = codigo.significado.toUpperCase();
            const searchFilter = search.toUpperCase();
            if(searchFilter.length !== 0) {
                if(!significado.includes(searchFilter)) {
                    return false;
                }
            }

            return true;
        });
    }

    public static async getDoctorFilters(
        token: string,
        sucursalId?: number,
        especialidad?: number[], 
        tipoDoctor?: string
    ): Promise<DoctorFilterResponse> {
        const response = await axios.get<DoctorFilterResponse>(
            "/api/agenda/doc-filters", 
            { 
                params: { sucursalId, especialidad, tipoDoctor },
                paramsSerializer: { indexes: true },
                headers: { Authorization: `Bearer ${token}` }
            }
        );
        const { data } = response;
        return data;
    }

    public static async GetSucursalesForAgendaFilters(token: string): Promise<SucursalFromDbCitas[]> {
        const response = await axios.get<SucursalFromDbCitas[]>(
            "/api/agenda/sucursal-filters",
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );
        const { data } = response;
        return data;
    }
}