import { ChangeEvent, Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { FiltrosModel, ShowFiltros } from "../../../types";
import { EspecialidadDropMenuFilter } from "../EspecialidadDropMenuFilter";
import { DoctoresDropMenuFilter } from "../DoctoresDropMenuFilter";
import { useUser } from "../../../hooks/useUser";
import { DoctorForFilter, SucursalFromDbCitas } from "../../../types/filter";
import { FiltroServices } from "../../../services";
import { DoctorTag } from "./AgendaFilter/DoctorTag";
import { EspecialidadTag } from "./AgendaFilter/EspecialidadTag";
import { especialidades } from "../../../utilities/StateEnums";
import { SucursalDropMenuFilter } from "../SucursalDropMenuFilter";
import { SucursalTag } from "./AgendaFilter/SucursalTag";

interface Props {
    filtros: FiltrosModel
    setFiltros: Dispatch<SetStateAction<FiltrosModel>>
    showFiltros: ShowFiltros
}

export function AgendaFiltros({ filtros, setFiltros, showFiltros }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [sucursales, setSucursales] = useState<SucursalFromDbCitas[]>([]);
    const [doctores, setDoctores] = useState<Array<DoctorForFilter>>([]);

    const onFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFiltros({
            ...filtros,
            [event.target.name]: event.target.value
        });
    }

    useEffect(() => {
        FiltroServices.GetSucursalesForAgendaFilters(token)
            .then(data => setSucursales(data));
    }, []);

    useEffect(() => {
        FiltroServices.getDoctorFilters(token, filtros.sucursalId, filtros.especialidad, filtros.tipoDoctor)
            .then(data => setDoctores(data.doctores))
    }, [filtros.sucursalId, filtros.especialidad, filtros.tipoDoctor]);

    return (
        <div className="w-full p-2 border-2 border-slate-200 rounded-lg shadow-sm py-4">
            <form className="grid grid-cols-4 w-full gap-y-3 sm:gap-y-2 gap-x-3 text-left">

                {showFiltros.fechas && (
                    <>
                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3" htmlFor="fechaInicio">Fecha inicial:</label>
                        <input 
                            className="inputdate w-11/12 sm:w-2/3"
                            id="fechaInicio"
                            type="date"
                            name="fechaInicial"
                            onChange={onFilterChange}
                            value={filtros.fechaInicial}
                            color="primary"/>
                    </div>

                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3" htmlFor="fechaFinal">Fecha final:</label>
                        <input 
                            className="inputdate w-11/12 sm:w-2/3"
                            id="fechaFinal"
                            type="date"
                            onChange={onFilterChange}
                            value={filtros.fechaFinal}
                            name="fechaFinal"
                            color="primary"/>
                    </div>
                    </>
                )}

                {showFiltros.sucursal && (
                    <SucursalDropMenuFilter 
                        setFiltros={setFiltros}
                        filtros={filtros}
                        sucursales={sucursales}/>
                )}

                {showFiltros.especialidad && (
                    <EspecialidadDropMenuFilter
                        setFiltros={setFiltros}
                        filtros={filtros}/>
                )}

                {showFiltros.tipoDoctor && (
                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3">Tipo de Doctor:</label>
                        <select className="selectfilter w-11/12 sm:w-2/3"
                                name="tipoDoctor"
                                onChange={onFilterChange}>
                                <option value={"todos"} defaultChecked>Todas los tipos</option>
                                <option value={"general"} defaultChecked>Generales</option>
                                <option value={"especialista"} defaultChecked>Especialistas</option>
                        </select>
                    </div>
                )}

                {showFiltros.doctores && (
                    <DoctoresDropMenuFilter
                        doctores={doctores}
                        setFiltros={setFiltros}
                        filtros={filtros}/>
                )}

                {filtros.especialidad.length > 0 && (
                <div className="flex items-center w-full border-slate-200 col-span-2 rounded-lg sm:flex-row">
                    <label className="w-full sm:w-1/6 font-semibold">Esp. seleccionadas</label>
                    <div className="flex">
                    {filtros.especialidad.map((esp, index) => (
                    <Fragment key={index}>
                        <EspecialidadTag 
                            nombre={especialidades.find((val) => val.id === esp)?.nombre ?? ""}/>
                    </Fragment>
                    ))}
                    </div>
                </div>
                )}

                {filtros.doctores.length > 0 && (
                <div className="flex items-center w-full border-slate-200 col-span-2 rounded-lg">
                    <label className="w-full sm:w-1/6 font-semibold">Doc. seleccionados</label>
                    <div className="flex flex-wrap max-h-[68px] overflow-auto gap-2 sm:w-5/6">
                        {filtros.doctores.map((doc, index) => (
                        <Fragment key={index}>
                            <DoctorTag 
                                nombre={doctores.find((val) => val.id === doc)?.doctorName ?? ""}/>
                        </Fragment>
                        ))}
                    </div>
                </div>
                )}

                {filtros.sucursales.length > 0 && (
                <div className="flex items-center w-full border-slate-200 col-span-2 rounded-lg">
                    <label className="w-full sm:w-1/6 font-semibold">Suc. seleccionadas</label>
                    <div className="flex flex-wrap max-h-[68px] overflow-auto gap-2 sm:w-5/6">
                        {filtros.sucursales.map((suc, index) => (
                        <Fragment key={index}>
                            <SucursalTag
                                nombre={sucursales.find((val) => val.id === suc)?.nombre ?? ""}/>
                        </Fragment>
                        ))}
                    </div>
                </div>

                )}

            </form>
        </div>

    );
}