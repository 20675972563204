import { VistaGeneral } from "../types/agenda";
import { BaseService } from "./BaseService";


export class AgendaServices extends BaseService {
    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/api/agenda")
    }

    public async getVistaGeneralData(
        fechaInicial?: string, 
        fechaFinal?: string, 
        sucursales?: number[], 
        especialidad?: number[],
        doctores?: number[],
        tipoDoctor?: string
    ): Promise<VistaGeneral> {
        const response = await this.axios.get<VistaGeneral>("vista-general", {
            params: { 
                fechaInicial, 
                fechaFinal, 
                sucursales, 
                especialidad, 
                doctores,
                tipoDoctor,
            },
            paramsSerializer: { indexes: true, }
        });
        const { data } = response;
        return data;
    }

    public async getVistaSucursalData(
        fechaInicial?: string, 
        fechaFinal?: string, 
        sucursales?: number[], 
        especialidad?: number[],
        doctores?: number[],
        tipoDoctor?: string
    ): Promise<VistaGeneral> {
        const response = await this.axios.get<VistaGeneral>("vista-sucursal", {
            params: { 
                fechaInicial, 
                fechaFinal, 
                sucursales, 
                especialidad, 
                doctores,
                tipoDoctor,
            },
            paramsSerializer: { indexes: true, }
        });
        const { data } = response;
        return data;
    }

    public async getVistaEspecialidadData(
        fechaInicial?: string, 
        fechaFinal?: string, 
        sucursales?: number[], 
        especialidad?: number[],
        doctores?: number[],
        tipoDoctor?: string
    ): Promise<VistaGeneral> {
        const response = await this.axios.get<VistaGeneral>("vista-especialidad", {
            params: { 
                fechaInicial, 
                fechaFinal, 
                sucursales, 
                especialidad, 
                doctores,
                tipoDoctor,
            },
            paramsSerializer: { indexes: true, }
        });
        const { data } = response;
        return data;
    }

    public async getVistaDoctorData(
        fechaInicial?: string, 
        fechaFinal?: string, 
        sucursales?: number[], 
        especialidad?: number[],
        doctores?: number[],
        tipoDoctor?: string
    ): Promise<VistaGeneral> {
        const response = await this.axios.get<VistaGeneral>("vista-doctor", {
            params: { 
                fechaInicial, 
                fechaFinal, 
                sucursales, 
                especialidad, 
                doctores,
                tipoDoctor,
            },
            paramsSerializer: { indexes: true, }
        });
        const { data } = response;
        return data;
    }

    public async exportAgendaData(
        fechaInicial?: string, 
        fechaFinal?: string, 
        sucursalId?: number, 
        especialidad?: number[],
        doctores?: number[],
        tipoDoctor?: string
    ) {
        return await this.axios.get("export", {
            params: { 
                fechaInicial, 
                fechaFinal, 
                sucursalId, 
                especialidad, 
                doctores,
                tipoDoctor,
            },
            paramsSerializer: { indexes: true, }
        });
    }
}