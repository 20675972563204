import { useEffect, useState } from "react";
import { useUser } from "../../../hooks/useUser";
import { AgendaServices } from "../../../services";
import { ChartLayout, VistaGeneral } from "../../../types/agenda";
import { FiltrosModel } from "../../../types";
import { VistaGeneralChart } from "./VistaGeneralChart";
import { LoadingComponent } from "../../UtilsComponents";

interface Props {
    filtros: FiltrosModel;
    layout: ChartLayout;
}

export function VistaSucursal({ filtros, layout }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const agendaService = new AgendaServices(token);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dataVistaSucursal, setDataVistaSucursal] = useState<VistaGeneral>({
        labels: [],
        general: [],
        especialista: [],
        generalColors: [],
        especialistaColors: [],
        proyeccionGeneral: [],
        proyeccionEspecialista: [],
    });

    useEffect(() => {
        setIsLoading(true);

        agendaService.getVistaSucursalData(
            filtros.fechaInicial,
            filtros.fechaFinal,
            filtros.sucursales,
            filtros.especialidad,
            filtros.doctores,
            filtros.tipoDoctor,
        ).then(data => {
            setDataVistaSucursal(data);
            setIsLoading(false);
        });

    }, [filtros]);

    return (
        <div className={`${layout == "Row" ? "w-full" : "flex justify-center" } rounded-lg shadow-sm p-4 bg-slate-50 border-2 border-slate-200`}>
            <div className={`${layout == "Row" ? "" : "w-10/12"} p-4`}>
            {isLoading
            ? <LoadingComponent />
            : <VistaGeneralChart
                chartConfig={dataVistaSucursal} />
            }
            </div>
        </div>
    );
}